import React, {useState, useCallback, useEffect} from "react"
import {useDropzone} from 'react-dropzone'
import {useDispatch, useSelector} from "react-redux"
import ReactLoading from 'react-loading'
import { FaTimesCircle } from 'react-icons/fa'

import "./upload.scss"
import {formatBytes} from "../../../utils/data"
import {sendFilesDocuments} from "../../../redux/api/apiAction"
import {deleteFile} from "../../../redux/registration/registrationAction"
import {isDefined} from "../../../utils/data";

const Upload = () => {
    const dispatch = useDispatch()
    const {information}         = useSelector(({registrationReducer}) => registrationReducer)
    const {user}                = useSelector(({apiReducer}) => apiReducer)
    const [files, setFiles]     = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(files.length > 0)
            sendFiles()
    }, [files])

    const onDrop = useCallback(acceptedFiles => {
        setLoading(true)

        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => {}
            reader.onerror = () => {}
            reader.onload = () => {const binaryStr = reader.result}
            reader.readAsArrayBuffer(file)
            onFilesAdded(file)
        })

        setLoading(false)
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const onFilesAdded = file => {
        setFiles(prevState => prevState.concat([file]))
    }

    const sendFiles = (e = null) => {
        setLoading(true)

        if(e !== null)
            e.preventDefault()

        dispatch(sendFilesDocuments(files))
            .then(setLoading(false))
    }

    const deleteFiles = () => {
        setFiles([])
        dispatch(deleteFile())
    }

    return (
        <div className={"sr-upload"}>

            {information.registered_file.value.length > 0 ?
                    <>
                    <div className={"row"}>
                        <div className={"title mx-auto"}>
                        Your supporting document
                        </div>
                    </div>
                    <div className={"content"}>
                        <a className={"sr-btn-next btn btn-info"} target={"_blank"} href={information.registered_file.value}>
                            Your document
                        </a>
                        <span className={"delete-file"} title={"Delete the file"} onClick={deleteFiles}><FaTimesCircle/></span>
                    </div>
                    </>
                :
                    <>
                    <div className={"row"}>
                        <div className={"title mx-auto"}>
                        Upload your supporting document here
                        </div>
                            <small>Copy of student card, letter from your employer if you are practicing nurse, or any valid ID card showing the date of birth if you are a young professionals.<br/> File format accepted (max 8MB) : pdf, jpg, png</small>
                    </div>
                    <div className={"content"}>
                        <div className={"drop-zone"} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {loading ? <ReactLoading type={"spin"} color={"#00A7CC"} height={30} width={30} className={"sr-loading"} /> : ""}
                            {
                                isDragActive ?
                                    <div className={"zone"}>Drop the files here ...</div> :
                                    <div className={"zone"}>Drag & drop some files here, or click to select files</div>
                            }
                        </div>

                        <div className={"files"}>
                            {files.map((file, i) => {
                                    return (
                                        <div key={i} className="row">
                                            {information.registered_file.value.length > 0 ?
                                                <div className={"col-lg-4 mx-auto"}>
                                                    <a className={"sr-btn-next btn btn-info"} href={information.registered_file.value} target={"_blank"}>{"My document"}</a>
                                                    <span className={"delete-file"} title={"Delete the file"} onClick={deleteFiles}><FaTimesCircle/></span>
                                                </div>
                                                : "" }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    </>
                }
        </div>
    )
}

export default Upload
