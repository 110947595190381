import {isDefined} from "./data"

export const getTotal = (ticket, options) => {
    let total = isDefined(ticket) ? parseFloat(ticket.packages_price) : 0

    isDefined(options) ?
        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })
   : total = total + 0

    return parseFloat(total).toFixed(2)
}

export const getTotalOfMembers = (members) => {
    let total = 0

    members.forEach(member => {
        const ticket    = member.registered_package.idpackage
        const options   = member.registered_option


        isDefined(options) ?
            options.forEach(option => {
                total = total + parseFloat(option.options_price)
            })
            : total = total + 0
    })

    return parseFloat(total).toFixed(2)
}
