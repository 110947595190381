import React from "react"

import SRModal from "../../../components/modal/modal"

const RefundModal = () => {
    return (
        <SRModal text={"Refund"} title={"Refund information"} className={"bankTransfer"}>
            <p>
                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
            </p>

            <ul>
                <li>Account Holder:        UICTMR (The Union)</li>
                <li>Bank name:   BNP PARIBAS</li>
                <li>Bank Adress: Paris Associations, 8 rue Sainte Cécile, 75009 Paris, France.</li>
                <li>Bank Code:    30004</li>
                <li>Code Branch: 00969</li>
                <li>Account n°:    00010070621</li>
                <li>Banking identification: 20</li>
                <li>IBAN: FR76 3000 4009 6900 0100 7062 120</li>
                <li>Swift code: BNPAFRPPPAA</li>
                <li>Currency: EUR</li>
            </ul>
        </SRModal>
    )
}

export default RefundModal
