import React from "react"
import PropTypes from "prop-types"

const CheckboxInput = ({name, value, onChange, placeholder}) => {
    return (
        <div>
            <input id={name} type={"checkbox"} name={name} value={value} checked={value} onChange={e => onChange(e)}/>
            <label htmlFor={name}>{placeholder}</label>
        </div>
    )
}

CheckboxInput.propTypes = {
    name:       PropTypes.string.isRequired,
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
}

export default CheckboxInput
