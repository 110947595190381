import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import "./information.scss"
import CheckboxInput from "../../components/form/checkboxInput"
import {validTab} from "../../redux/tabs/tabsAction"
import AlertCategory from "./alertCategory/alertCategory"
import HelpUr from "../../components/help/help"
import {onChangeInformation} from "../../redux/registration/registrationAction"

const Information = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {information} = useSelector(({registrationReducer}) => registrationReducer)
    const {registered_interest, registered_intouch} = information

    const onSubmit = (e = null) => {
        const form = e.currentTarget
        
        if(registered_interest.value.length > 0 && registered_intouch.value.length > 0) {
            dispatch(validTab("informationTab"))
            nextTab()
        }        
        else {

        }

        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        //dispatch(submitInformation(user, values, nextTab))
    }

    const getValue = (name, isInterest = true) => {
        if(isInterest)
            return registered_interest.value.indexOf(name) !== -1
        else
            return registered_intouch.value.indexOf(name) !== -1
    }
    
    return (
        <div className={"content information"}>
                    <AlertCategory/>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12"}>
                            <p className={"title"}>Centre d'intérêt *</p>

                            <div className="form-row">
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Gestion"} onChange={e => dispatch(onChangeInformation(e))} name={"Gestion"} value={getValue("Gestion ")}/>
                                    <CheckboxInput placeholder={"Financet"} onChange={e => dispatch(onChangeInformation(e))} name={"Finance"} value={getValue("Finance")}/>
                                    <CheckboxInput placeholder={"Fiscalité"} onChange={e => dispatch(onChangeInformation(e))} name={"Fiscalite"} value={getValue("Fiscalite")}/>
                                </div>
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Gestion sociale"} onChange={e => dispatch(onChangeInformation(e))} name={"gestion sociale"} value={getValue("gestion sociale")}/>
                                    <CheckboxInput placeholder={"Management"} onChange={e => dispatch(onChangeInformation(e))} name={"Management"} value={getValue("Management")}/>
                                    <CheckboxInput placeholder={"Statistiques"} onChange={e => dispatch(onChangeInformation(e))} name={"Statistiques"} value={getValue("Statistiques")}/>
                                </div>
                            </div>
                            {information.registered_interest.error !== "" ?
                                <div className="invalid-feedback">{information.registered_interest.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>
 <div className="form-group">
                    <div className={"form-row"}>
                        <div className={"col-12"}>
                            <p className={"title"}>Keep in touch *</p>
                            <p className={"small"}>
                                The Union would like to keep you updated about our work and the latest news, research and vital information on lung health. Please read our <a href="https://www.theunion.org/privacy-policy" target="_blank">Privacy Policy</a> and then tell us what you would like to hear about:
                            </p>
                            

                            <div className="form-row">
                                <div className={"col-12 col-lg-6"}>
                                    <CheckboxInput placeholder={"Conferences and events"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Conferences and events"} value={getValue("Conferences and events", false)}/>
                                    <CheckboxInput placeholder={"Union newsletter"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Union newsletter"} value={getValue("Union newsletter", false)}/>
                                    <CheckboxInput placeholder={"Courses"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Courses"} value={getValue("Courses", false)}/>
                                </div>
                                <div className={"col-12 col-lg-6"}>
                                        
                                    <CheckboxInput placeholder={"Membership"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Membership"} value={getValue("Membership", false)}/>
                                    <CheckboxInput placeholder={"Centennial Dinner"} onChange={e => dispatch(onChangeInformation(e, false))} name={"Centennial Dinner"} value={getValue("Centennial Dinner", false)}/>                                    
                                    <CheckboxInput placeholder={"None of the above"} onChange={e => dispatch(onChangeInformation(e, false))} name={"None of the above"} value={getValue("None of the above", false)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>


            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Précédent</button>
                    <small  className={"help"}><HelpUr/></small>
                <button className={"sr-btn-next btn btn-info"} disabled={!(registered_interest.value.length > 0 && registered_intouch.value.length > 0)} onClick={onSubmit}>Suivant</button>
            </div>
        </div>
    )
}

Information.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Information
