import React, {useEffect, useState} from 'react'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'
import ReactLoading from 'react-loading'
import {useDispatch, useSelector} from "react-redux"
import {navigate} from "gatsby"

import CardSection from './cardSelection'
import {submitStripe} from "../../../../redux/registration/registrationAction"

const CheckoutForm = ({clientSecret, total}) => {
    const dispatch  = useDispatch()
    const stripe    = useStripe()
    const elements  = useElements()

    const [loading, setLoading] = useState(false)
    const stripeReducer = useSelector(({registrationReducer}) => registrationReducer.stripe)
    const information   = useSelector(({registrationReducer}) => registrationReducer.information)

    useEffect(() => {
        if(stripeReducer.success === true)
            navigate("confirmation-payment")
    }, [stripeReducer.success])

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: information.registered_firstname + " " + information.registered_lastname,
                },
            }
        });

        dispatch(submitStripe(result))
        setLoading(false)
    };

    return (
        <form onSubmit={handleSubmit} className={"form-payment"}>
            {stripeReducer.error.length > 0 ? <div className={"stripe-message stripe-error"}>{stripeReducer.error}</div> : ""}
            <div className={"stripe-message stripe-success"}>{stripeReducer.success === true ? "Payment succeeded !" : ""}</div>
            {loading === true ? <ReactLoading type={"spin"} color={"#00A7CC"} height={30} width={30} className={"sr-loading"} /> : ""}
            <CardSection />
            <button className={"btn-payment"} disabled={!stripe}>{stripeReducer.error.length > 0 ? ("Retry your order (" + total +"€)") : ("Confirm order (" + total + "€)")}</button>
        </form>
    )
}

export default CheckoutForm
