import React from "react"
import PropTypes from "prop-types"
import {useDispatch} from "react-redux"

import {onChangeByName} from "../../redux/registration/registrationAction"
import {getCountryCallingCode} from "libphonenumber-js"

const countries = require("i18n-iso-countries")

const CountriesInput = ({value, onChange, disabled, inputCountry, inputPhone}) => {
    const dispatch = useDispatch()
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
    const countriesName = countries.getNames("en")

    const onChangeCountry = (e) => {
        const countryCode = countries.getAlpha2Code(e.target.value, "en")
        onChange(e)
        
        dispatch(onChangeByName("countryCode", countryCode))
        dispatch(onChangeByName(inputPhone, "+" + getCountryCallingCode(countryCode)))
    }

    return (
        <select className="form-control" placeholder="Country" name={inputCountry} value={value} onChange={e => onChangeCountry(e)} disabled={disabled} required>
        <option value="" disabled selected>Country</option>    
        {Object.values(countriesName).map((country, i) => (
                <option key={i} value={country}>{country}</option>
            ))}
        </select>
    )
}

CountriesInput.propTypes = {
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    disabled:   PropTypes.bool,
    inputCountry: PropTypes.string,
    inputPhone: PropTypes.string,
}

CountriesInput.defaultProps = {
    disabled: false,    
    inputCountry: "registered_country",
    inputPhone: "registered_phone",
}

export default CountriesInput
