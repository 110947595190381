import React from "react"
import PropTypes from "prop-types"
import { FaTicketAlt } from 'react-icons/fa'

import "./roundTicket.scss"

const RoundTicket = ({name, price, isSelected, onClick, isOption, readOnly, description, isLimited}) => (
    <div className={"col-6 col-md-3 " + (isOption ? "col-lg-3": "col-lg-3")} onClick={() => readOnly ? {} : onClick()}>
        <div className={"round-ticket " + (isSelected ? "selected": "") + (isLimited ? " option-limited": "")}>
            <div className={"circle"}>
                <div className={"icon"}>
                    <FaTicketAlt/>
                </div>
            </div>
            <div className={"name"}>{name}</div>
            <div className={"price"}>{parseInt(price) > 0 ? (price  + " EUROS") : ""}</div>
            <div className={"description"} dangerouslySetInnerHTML={{ __html: description }}/>
        </div>
    </div>
)

RoundTicket.propTypes = {
    name:       PropTypes.string,
    price:      PropTypes.string,
    description:PropTypes.string,
    isSelected: PropTypes.bool,
    onClick:    PropTypes.func.isRequired,
    isOption:   PropTypes.bool,
    readOnly:   PropTypes.bool,
    isLimited:  PropTypes.bool,
}

RoundTicket.defaultProps = {
    name: "",
    price: 0,
    description: "",
    isSelected: false,
    isOption: false,
    readOnly: false,
    isLimited: false,
}

export default RoundTicket
