import React from "react"
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { FaInfo, FaIdCard, FaTicketAlt, FaTools, FaNewspaper, FaDatabase } from 'react-icons/fa'

import "./tabSingleInformation.scss"
import {useSelector} from "react-redux";

const TabSingleInformation = ({about, documents, ticketComponent, options, information, yourData, summary, index, onSelect}) => {
    const {user} = useSelector(({apiReducer}) => apiReducer)
    const { ticketTab, optionsTab, informationTab, yourDataTab, documentsTab, summaryTab} = useSelector(({tabsReducer}) => tabsReducer)
    const tabs = [        
        {
            text: "Profil",
        },
        {
            text: "Ticket",
        },
        {
            text: "Options",
        },
        {
            text: "Informations",
        },
        {
            text: "Autorisations",
        },
        {
            text: "Documents",
        },
        {
            text: "Résumé",
        }]

    return (
        <Tabs className={"sr-tab"} selectedIndex={index} onSelect={onSelect}>
            <TabList>
                {tabs.map((tab, i) => {
                    return (
                    user === null ?
                        ticketTab === false  && i > 0 ?
                            ""
                            :
                            optionsTab === false && i > 1 ?
                                ""
                                :
                                    informationTab === false && i > 2 ?
                                        ""
                                        :
                                        yourDataTab === false && i > 3 ?
                                            ""
                                            :
                                            documentsTab === false && i > 4 ?
                                                ""
                                                :
                                                summaryTab === false && i > 5 ?
                                                    ""
                                                    :
                                <Tab key={i}>
                                    <span className={"text"}>{tab.text}</span>
                                </Tab>
                                        :
                        <Tab key={i}>
                            <span className={"icon"}>{tab.icon} </span>
                            <span className={"text"}>{tab.text}</span>
                        </Tab>
                    )})}
                <div className={"moving-tab"} style={{left: index * 14.3 + "%"}}>
                    <span className={"icon"}>{tabs[index].icon} </span>
                    <span className={"text"}>{tabs[index].text}</span>
                </div>
            </TabList>

            
            <TabPanel>
                {about}
            </TabPanel>
            <TabPanel>
                {ticketComponent}
            </TabPanel>
            <TabPanel>
                {options}
            </TabPanel>
            <TabPanel>
                {information}
            </TabPanel>
            <TabPanel>
                {yourData}
            </TabPanel>
            <TabPanel>
                {documents}
            </TabPanel>
            <TabPanel>
                {summary}
            </TabPanel>
        </Tabs>
    )
}


TabSingleInformation.propTypes = {
    about:      PropTypes.node.isRequired,
    documents:  PropTypes.node.isRequired,
    ticketComponent:     PropTypes.node.isRequired,
    options:    PropTypes.node.isRequired,
    information: PropTypes.node.isRequired,
    yourData:   PropTypes.node.isRequired,
    summary:    PropTypes.node.isRequired,
    index:      PropTypes.number.isRequired,
    onSelect:   PropTypes.func.isRequired,
}

export default TabSingleInformation
