import React from 'react'
import {CardElement} from '@stripe/react-stripe-js'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
            backgroundColor: "#ffffff",
            border: "1px solid grey",
            borderRadius: "5px",
            boxShadow: "0 4px 5.5px 0 rgba(0,0,0,.07)",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const CardSection = () => {
    return (
        <CardElement options={CARD_ELEMENT_OPTIONS} />
    )
};

export default CardSection
