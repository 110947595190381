import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"

import RoundTicket from "../../components/roundTicket/roundTicket"
import { selectTicket} from "../../redux/registration/registrationAction"
import {getOptionsFormTicket} from "../../redux/api/apiAction"
import {validTab} from "../../redux/tabs/tabsAction"
import AlertCategory from "./alertCategory/alertCategory"
import {isDefined} from "../../utils/data"
import HelpUr from "../../components/help/help"


const Ticket = ({prevTab, nextTab}) => {
    const dispatch = useDispatch()
    const { category, ticket }    = useSelector(({registrationReducer}) => registrationReducer)
    const { user } = useSelector(({apiReducer}) => apiReducer)
    const [items, setItems] = useState([])

    const disabledInput = isDefined(user)

    useEffect(() => {
        if(category !== null)
            setItems(category.package)
    }, [category])

    const [itemSelected, setItemSelected] = useState(ticket !== null ? ticket.idpackages : null)

    const selectItem = (item) => {
        if(item !== undefined)
            if(item !== null) {
                setItemSelected(item.idpackages)
                dispatch(selectTicket(item))
                dispatch(getOptionsFormTicket(category.idcategories, item.idpackages))
                dispatch(validTab("optionsTab"))
            }
    }

    return (
        <div className={"content tickets"}>
               <AlertCategory/>

            <p className={"title"}>Choisissez votre ticket</p>
          
            <form>
                <div className={"row row-tickets"}>
                    {disabledInput && ticket !== null ?
                        <RoundTicket name={ticket.packages_name}
                                     price={ticket.packages_price}
                                     description={ticket.packages_desc}
                                     isSelected={ticket.idpackages === itemSelected}
                                     onClick={() => {}}/>
                    :
                    items.map((item, i) =>
                            <RoundTicket key={i}
                                     name={item.packages_name}
                                     price={item.packages_price}
                                     description={item.packages_desc}
                                     isSelected={item.idpackages === itemSelected}
                                     onClick={() => selectItem(item)}
                        />
                     )}
                </div>
            </form>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Précédent</button>
                <small  className={"help"}><HelpUr/></small>
                <button className={"sr-btn-next btn btn-info"} disabled={itemSelected === null} onClick={nextTab}>Suivant</button>
            </div>
        </div>
    )
}

Ticket.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Ticket
