import React from "react"
import PropTypes from "prop-types"
const countries = require("i18n-iso-countries")

const CountriesGeneralInput = ({name, value, onChange, placeholder, required}) => {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
    const countriesName = countries.getNames("en")

    return (
        <select id={name} className="form-control" placeholder={placeholder} name={name} value={value} onChange={e => onChange(e)} required={required}>
        <option value="" disabled selected>Country</option>    
        {Object.values(countriesName).map((country, i) => (
                <option key={i} value={country}>{country}</option>
            ))}
        </select>
    )
}

CountriesGeneralInput.propTypes = {
    name:       PropTypes.string.isRequired,
    value:      PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required:   PropTypes.bool,
}

CountriesGeneralInput.defaultProps = {
    placeholder: "Pays",
    required: true,
}

export default CountriesGeneralInput
