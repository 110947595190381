import React from 'react'

import SRModal from "../modal/modal"

const HelpUr = () => (
    <SRModal title={"Besoin d'aide?"} text={"Besoin d'aide?"}>
        <p>
Any questions?<br/>
Get in touch with us. We are more than happy to help you!<br/>
<a href="mailto:registration-theunion@urevent.fr">registration-theunion@urevent.fr</a>
    </p>
    </SRModal>
)
SRModal.propTypes = {    
    text:       "Besoin d'aide?",
    title:      "Besoin d'aide?",
}
export default HelpUr
